import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = [
  {
    responsive_id: '',
    id: 1,
    policy_number: '2/1/020/02300842 RN19-20',
    insurance_company: "RSA INS",
    policy_type: '1203',
    client: '10311',
    approval: 2,
    created_on: "1 Days ago",
    created_by: "Shiji"
  },
  {
    responsive_id: '',
    id: 2,
    policy_number: '1/1/020/30530555/1',
    insurance_company: "RSA INS",
    policy_type: '401',
    client: '10316',
    approval: 2,
    created_on: "5 Days ago",
    created_by: "Shiji"
  },
  {
    responsive_id: '',
    id: 3,
    policy_number: '1/1/020/30331054/3',
    insurance_company: "RSA INS",
    policy_type: '401',
    client: '10316',
    approval: 3,
    created_on: "6 Days ago",
    created_by: "Shiji"
  },
  {
    responsive_id: '',
    id: 4,
    policy_number: '1/1/020/30264321/3',
    insurance_company: "RSA INS",
    policy_type: '401',
    client: '10316',
    approval: 4,
    created_on: "1 Days ago",
    created_by: "Shiji"
  },
  {
    responsive_id: '',
    id: 5,
    policy_number: '813/C/437730',
    insurance_company: "NIA - DXB",
    policy_type: '403',
    client: '10316',
    approval: 5,
    created_on: "2 Days ago",
    created_by: "Shiji"
  }
  
]

mock.onGet('/api/datatables/initial-data').reply(() => {
  return [200, data]
})

mock.onGet('/api/datatables/data').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1 } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.filter(
    item =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      item.policy_number.toLowerCase().includes(queryLowered) ||
      item.insurance_company.toLowerCase().includes(queryLowered) ||
      item.policy_type.toLowerCase().includes(queryLowered) ||
      item.created_on.toLowerCase().includes(queryLowered) ||
      item.created_by.toLowerCase().includes(queryLowered) ||
      item.client.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      allData: data,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})
